import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/",
    redirect: "/users",
    component: () => import("../views/index.vue"),
    children: [
      {
        path: "/users",
        name: "Users",
        component: () => import("../views/Users.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  const NAME = sessionStorage.getItem("eman");
  const PWD = sessionStorage.getItem("drowssap");
  const TOKEN = sessionStorage.getItem("token");
  if (to.name !== "Login" && !NAME && !PWD && !TOKEN)
    next({
      name: "Login",
    });
  else next();
});

export default router;
